import {
  DeleteFilled,
  EllipsisOutlined,
  LeftOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Avatar, Dropdown, Layout, Menu } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { AppContext } from '../AppContext';
import defaultIcon from '../assets/svg/default-app.svg';
import { ACTIONS, GA_EVENT, GA_LABEL, ROUTES } from '../common/constants';
import { googleAnalyticsEvents } from '../common/utils';
import DeleteApp from '../modules/apps/components/DeleteAppModal';
import { DELETE_APPLICATION } from '../modules/apps/graphql/mutations';
import { GET_APP } from '../modules/apps/graphql/queries';
import '../modules/apps/style/app-name-header.less';
import { GET_TEAM } from '../modules/teams/graphql/queries';
import AccessControl from './AccessControl';
import LoaderComponent from './LoaderComponent';

const { Header } = Layout;

const AppNameHeader = ({ refetchData, setRefetchData }) => {
  const location = useLocation();
  const setLocation = location?.pathname?.split('/');
  const history = useHistory();
  const appID = useParams();
  const id = appID?.appId;
  const {
    state: { currentUser, teamId, teamPlan },
    dispatch,
  } = useContext(AppContext);

  const [teamData, setTeamData] = useState();
  const [deleteAppModal, setDeleteAppModal] = useState();
  const [btnLoading, setBtnLoading] = useState(false);

  const [getAppDetails, { data }] = useLazyQuery(GET_APP, {
    fetchPolicy: 'network-only',
    onError() {},
  });

  const [getTeam] = useLazyQuery(GET_TEAM, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      setTeamData(res?.getTeam?.teamDetails);
    },
  });

  useEffect(() => {
    if (teamId) {
      getTeam({
        variables: { teamId },
      });
    }
  }, [teamId]);

  useEffect(() => {
    getAppDetails({
      variables: {
        appId: id,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (refetchData) {
      getAppDetails({
        variables: {
          appId: id,
        },
      });
      setRefetchData(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchData]);

  const BackToApps = () => {
    if (location?.pathname?.includes(ROUTES?.APP_SETTINGS)) {
      history?.goBack();
    } else {
      history?.push(ROUTES?.APPS);
    }
  };

  const [deleteAppMutation] = useMutation(DELETE_APPLICATION, {
    variables: {
      appId: id,
    },
    onCompleted() {
      googleAnalyticsEvents(GA_EVENT?.APP_DELETED, {
        label: GA_LABEL?.APP_DELETED,
        member_id: currentUser?.id,
        current_plan_name: teamPlan?.subscriptionPlan?.name,
        current_plan_type: teamPlan?.subscriptionPlan?.type,
      });
      setBtnLoading(false);
      dispatch({
        type: 'SET_REFETCH_TEAM_PLAN_USAGE',
        data: true,
      });
      BackToApps();
    },
    onError() {
      setBtnLoading(false);
    },
  });

  if (btnLoading) {
    <LoaderComponent />;
  }

  const items = [
    {
      key: 'app-settings',
      label: (
        <Link
          className="d-flex align-center"
          to={`${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.APP_SETTINGS}/${id}`}
        >
          <div
            className="d-flex align-center"
            onClick={() => {
              googleAnalyticsEvents(GA_EVENT?.CLICK_APP_SETTING, {
                label: GA_LABEL?.CLICK_APP_SETTING,
                member_id: currentUser?.id,
                current_plan_name: teamPlan?.subscriptionPlan?.name,
                current_plan_type: teamPlan?.subscriptionPlan?.type,
              });
            }}
          >
            <SettingOutlined className="mr-8" />
            App Settings
          </div>
        </Link>
      ),
    },
    {
      key: 'menu-header-divider',
      type: 'divider',
      className: 'menu-header-divider',
    },
    ...(AccessControl({ allowedPermissions: ACTIONS?.DELETE_APP }) ||
    data?.getApplication?.appCreatedBy === currentUser?.id
      ? [
          {
            key: 'deleteApp',
            label: (
              <div
                className="d-flex align-center"
                onClick={() => {
                  setDeleteAppModal(true);
                  googleAnalyticsEvents(GA_EVENT?.DELETE_APP_INITIATED, {
                    label: GA_LABEL?.DELETE_APP_INITIATED,
                    member_id: currentUser?.id,
                    current_plan_name: teamPlan?.subscriptionPlan?.name,
                    current_plan_type: teamPlan?.subscriptionPlan?.type,
                  });
                }}
              >
                <DeleteFilled className="mr-8" />
                Delete App
              </div>
            ),
            onClick: () => setDeleteAppModal(true),
            danger: true,
          },
        ]
      : []),
  ];

  const headerItems = [
    {
      key: 'releases',
      label: (
        <Link to={`${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.RELEASES}/${id}`}>
          Releases
        </Link>
      ),
    },
    AccessControl({
      allowedPermissions: ACTIONS?.UPDATE_APP_SERVICE,
    }) && {
      key: 'apps-services',
      label: (
        <Link
          to={`${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.APPS_SERVICES}${ROUTES?.UPDATE}/${id}`}
        >
          App Services
        </Link>
      ),
    },
    {
      key: 'app-share',
      label: (
        <Link to={`${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.SHARE}/${id}`}>
          Members
        </Link>
      ),
    },
    // comment for prod AOA-1156
    // !isFreePlan && {
    //   key: 'dynamic-link',
    //   label: (
    //     <Link
    //       to={`${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.DYNAMIC_LINK}/${id}`}
    //     >
    //       Dynamic Link
    //     </Link>
    //   ),
    // },
    teamData?.allowFeedbacks && {
      key: 'feedbacks',
      label: (
        <Link
          to={`${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.FEEDBACKS}/${id}`}
        >
          Feedbacks
        </Link>
      ),
    },
  ];

  return (
    <>
      <Header className="app-name-header">
        <div className="app-name-header-layout d-flex justify-center align-center">
          <div className="app-name-header-left">
            <LeftOutlined className="mr-25" onClick={BackToApps} />
            <Avatar
              src={data?.getApplication?.appLogo || defaultIcon}
              alt="application-img"
              className="avatar-width"
              size={28}
            />
            <span
              className="header-app-name font-16"
              title={data?.getApplication?.appName}
            >
              {data?.getApplication?.appName}
            </span>
          </div>

          <div className="app-name-header-middle">
            <Menu
              mode="horizontal"
              selectedKeys={setLocation?.[3]}
              className="d-flex justify-center"
              items={headerItems}
            />
          </div>

          <div className="app-name-header-right">
            <Dropdown
              overlayClassName="app-menu"
              menu={{ items }}
              trigger={['click']}
              placement="topRight"
              getPopupContainer={(triggerNode) => triggerNode}
              dropdownRender={(menu) => (
                <div className="app-name-header">
                  {React.cloneElement(menu)}
                </div>
              )}
            >
              <EllipsisOutlined rotate={90} />
            </Dropdown>
          </div>
        </div>
      </Header>

      <DeleteApp
        showDeleteAppModal={deleteAppModal}
        handleVisible={setDeleteAppModal}
        mutation={deleteAppMutation}
        deleteAppData={data}
        btnLoading={btnLoading}
      />
    </>
  );
};

export default AppNameHeader;
