import { useMutation, useQuery } from '@apollo/client';
import { Button, Card, Divider, Form, Input, Row, Upload } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { messageContext } from '../../app/components/AppContextHolder';
import editIcon from '../../assets/images/edit-icon.png';
import {
  ACTIONS,
  ALL_IMAGE_UPLOAD,
  GA_EVENT,
  GA_LABEL,
} from '../../common/constants';
import {
  formValidatorRules,
  googleAnalyticsEvents,
  previewImage,
} from '../../common/utils';
import AccessControl from '../../components/AccessControl';
import AppNameHeader from '../../components/AppNameHeader';
import LoaderComponent from '../../components/LoaderComponent';
import { GET_SIGNED_URL } from '../onboard/graphql/mutation';
import './app-settings.less';
import { UPDATE_APPLICATION } from './graphql/mutations';
import { GET_APP } from './graphql/queries';

const {
  required,
  urlAppStoreAppsOnAir,
  urlPlayStoreAppsOnAir,
} = formValidatorRules;

const AppSettings = () => {
  const {
    state: { currentUser, teamPlan },
  } = useContext(AppContext);
  const appID = useParams();
  const id = appID?.appId;

  const [form] = Form.useForm();

  const [btnLoading, setBtnLoading] = useState(false);
  const [currentFile, setCurrentFile] = useState(null);
  const [refetchData, setRefetchData] = useState(false);
  const [initialValue, setInitialValue] = useState();
  const [imageUpload, setImageUpload] = useState();
  const [state, setState] = useState({
    selectedImage: {},
    uploading: false,
  });

  const noAppUpdatePermission = !AccessControl({
    allowedPermissions: ACTIONS?.UPDATE_APP,
  });

  const { selectedImage } = state;

  const { data } = useQuery(GET_APP, {
    fetchPolicy: 'network-only',
    variables: {
      appId: id,
    },
    onError(error) {
      return error;
    },
  });

  const [getSignedURL] = useMutation(GET_SIGNED_URL, {
    onError() {
      setBtnLoading(false);
      setState({ ...state, uploading: false, selectedImage: {} });
    },
  });

  const [updateApplication] = useMutation(UPDATE_APPLICATION, {
    refetchQueries: [{ query: GET_APP, variables: { appId: id } }],
    onCompleted(res) {
      setRefetchData(true);
      if (res?.updateApplication?.data?.appLogo) {
        setImageUpload([
          {
            url: res?.updateApplication?.data?.appLogo,
          },
        ]);
      } else {
        setImageUpload([]);
      }
    },
    onError() {
      setBtnLoading(false);
    },
  });

  useEffect(() => {
    if (data) {
      if (!initialValue) {
        setInitialValue({
          appName: data?.getApplication?.appName,
          androidUpdateLink: data?.getApplication?.androidUpdateLink,
          iosUpdateLink: data?.getApplication?.iosUpdateLink,
        });
      } else {
        form?.setFieldsValue({
          teamName: data?.getApplication?.appName,
        });
      }

      if (data?.getApplication?.appLogo) {
        setImageUpload([
          {
            url: data?.getApplication?.appLogo,
          },
        ]);
      } else {
        setImageUpload([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleUpload = async (values) => {
    setBtnLoading(true);
    // eslint-disable-next-line no-undef
    const formData = new FormData();
    formData?.append('file', selectedImage);
    setState({
      ...state,
      uploading: true,
    });

    if (currentFile) {
      const res = await getSignedURL({
        variables: {
          data: {
            fileName: currentFile?.name,
            fileType: currentFile?.type,
          },
        },
      });
      const config = {
        headers: {
          'Content-Type': currentFile?.type,
          'x-amz-acl': 'public-read',
        },
      };

      try {
        await axios?.put(
          res?.data?.getSignedUrl?.signedURL,
          currentFile,
          config,
        );
        const { appName, androidUpdateLink, iosUpdateLink } = values;
        const appData = {
          appId: id,
          appName,
          appLogo: res?.data?.getSignedUrl?.fileUrl,
          androidUpdateLink,
          iosUpdateLink,
        };

        await updateApplication({
          variables: {
            data: appData,
          },
        });
        setBtnLoading(false);
        googleAnalyticsEvents(GA_EVENT?.CHANGE_APP_ICON, {
          label: GA_LABEL?.CHANGE_APP_ICON,
          member_id: currentUser?.id,
          current_plan_name: teamPlan?.subscriptionPlan?.name,
          current_plan_type: teamPlan?.subscriptionPlan?.type,
        });
      } catch (err) {
        return err;
      }
    } else {
      updateApplication({
        variables: {
          data: {
            appId: id,
            appName: values?.appName,
            androidUpdateLink: values?.androidUpdateLink,
            iosUpdateLink: values?.iosUpdateLink,
            appLogo: imageUpload?.[0]?.url || null,
          },
        },
      });
      setBtnLoading(false);
    }
    googleAnalyticsEvents(GA_EVENT?.SAVE_APP_SETTING, {
      label: GA_LABEL?.SAVE_APP_SETTING,
      member_id: currentUser?.id,
      current_plan_name: teamPlan?.subscriptionPlan?.name,
      current_plan_type: teamPlan?.subscriptionPlan?.type,
    });
  };

  const onChangeImageUpload = async (info) => {
    const {
      file: { name = '', url },
    } = info;

    const ext = name?.substring(name?.lastIndexOf('.') + 1);

    if (ALL_IMAGE_UPLOAD?.includes(ext) && !url) {
      setImageUpload([...info?.fileList]);
      setCurrentFile(info?.file);
    } else if (info?.file?.status === 'removed') {
      setCurrentFile(null);
      setImageUpload([]);
    } else {
      messageContext?.destroy();
      messageContext?.error(`${info?.file?.name} file is not image file.`);
    }
  };

  return (
    <>
      <AppNameHeader
        refetchData={refetchData}
        setRefetchData={setRefetchData}
      />
      {!initialValue ? (
        <LoaderComponent />
      ) : (
        <div className="app-card">
          <div className="app-card-content">
            <Card title="App Settings" bordered className="profile-card">
              <fieldset
                disabled={
                  noAppUpdatePermission &&
                  data?.getApplication?.appCreatedBy !== currentUser?.id
                }
              >
                <Form
                  className="app-settings"
                  layout="vertical"
                  form={form}
                  onFinish={handleUpload}
                  initialValues={initialValue}
                >
                  <Form.Item name="appLogo">
                    <div className="app-upload">
                      <Upload
                        maxCount={1}
                        onChange={onChangeImageUpload}
                        fileList={imageUpload}
                        beforeUpload={() => false}
                        previewFile={(file) => previewImage(file, 60)}
                        listType="picture-card"
                        showUploadList={{
                          showPreviewIcon: false,
                          removeIcon:
                            noAppUpdatePermission &&
                            data?.getApplication?.appCreatedBy !==
                              currentUser?.id,
                        }}
                      >
                        {!imageUpload?.length && (
                          <img
                            src={editIcon}
                            alt="app-icon"
                            className="icon-image"
                          />
                        )}
                      </Upload>
                      <span className="profile-heading">Change app icon</span>
                    </div>
                  </Form.Item>
                  <Form.Item
                    name="appName"
                    label="App name"
                    rules={[
                      {
                        ...required,
                        message: 'Enter valid app name',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="androidUpdateLink"
                    label="Play store link / AppsOnAir Link"
                    rules={[urlPlayStoreAppsOnAir]}
                  >
                    <Input placeholder="Play store link / AppsOnAir Link" />
                  </Form.Item>
                  <Form.Item
                    name="iosUpdateLink"
                    label="Apple app store link / AppsOnAir Link"
                    rules={[urlAppStoreAppsOnAir]}
                  >
                    <Input placeholder="Apple app store link / AppsOnAir Link" />
                  </Form.Item>
                  {noAppUpdatePermission ||
                    (data?.getApplication?.appCreatedBy === currentUser?.id && (
                      <>
                        <Divider />
                        <Row className="upload-button-row">
                          <Button
                            type="primary"
                            htmlType="submit"
                            size="middle"
                            loading={btnLoading}
                            className="save-btn"
                          >
                            Save
                          </Button>
                        </Row>
                      </>
                    ))}
                </Form>
              </fieldset>
            </Card>
          </div>
        </div>
      )}
    </>
  );
};

export default AppSettings;
