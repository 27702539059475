import { gql } from '@apollo/client';

export const PURCHASE_ADD_ON = gql`
  mutation purchaseAddon($data: PurchaseAddonsInput!) {
    purchaseAddon(data: $data) {
      data {
        transactionId
        teamAddonId
        planId
      }
    }
  }
`;

export const CANCEL_ADD_ON = gql`
  mutation cancelAddon($data: TeamSubscriptionUniqueInput!) {
    cancelAddon(data: $data) {
      message
    }
  }
`;

export const EDIT_ADD_ON = gql`
  mutation updateAddonQuantity(
    $where: TeamSubscriptionUniqueInput!
    $data: UpdateAddonQuantityInput
  ) {
    updateAddonQuantity(where: $where, data: $data) {
      message
    }
  }
`;
