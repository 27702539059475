import { Avatar, Divider, Popover } from 'antd';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import userIcon from '../../../assets/images/user-icon.png';
import { GA_EVENT, GA_LABEL, ROUTES } from '../../../common/constants';
import { googleAnalyticsEvents } from '../../../common/utils';
import './user-profile.less';

const UserProfile = () => {
  const [visible, setVisible] = useState(false);
  const {
    state: { currentUser, teamPlan },
  } = useContext(AppContext);

  const history = useHistory();

  const handleVisibleChange = (value) => {
    setVisible(value);
  };

  const handleClick = () => {
    googleAnalyticsEvents(GA_EVENT?.USER_LOGOUT, {
      label: GA_LABEL?.USER_LOGOUT,
      member_id: currentUser?.id,
      current_plan_name: teamPlan?.subscriptionPlan?.name,
      current_plan_type: teamPlan?.subscriptionPlan?.type,
    });
    setVisible(false);
    history?.push(ROUTES?.LOGOUT);
  };

  const handleProfileClick = () => {
    googleAnalyticsEvents(GA_EVENT?.CLICK_PROFILE_SETTING, {
      label: GA_LABEL?.CLICK_PROFILE_SETTING,
      member_id: currentUser?.id,
      current_plan_name: teamPlan?.subscriptionPlan?.name,
      current_plan_type: teamPlan?.subscriptionPlan?.type,
    });
    setVisible(false);
    history?.push(ROUTES?.PROFILE_SETTINGS);
  };

  const userMenuOptions = (
    <div className="profile-popover">
      <ul className="gx-user-popover">
        <li onClick={handleProfileClick}>Profile Settings</li>
        <Divider className="profile-dropdown-divider" />
        <li onClick={handleClick} className="list-popover">
          Logout
        </li>
      </ul>
    </div>
  );

  return (
    <div className="gx-avatar-row">
      <Popover
        className="user-profile"
        placement="bottomRight"
        content={userMenuOptions}
        trigger="click"
        open={visible}
        onOpenChange={handleVisibleChange}
      >
        <Avatar
          src={currentUser?.profileImage || userIcon}
          alt="appIcon"
          className="gx-size-35 header-profile-icon"
          size={24}
        />
      </Popover>
    </div>
  );
};

export default UserProfile;
