import { InfoCircleOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import {
  Avatar,
  Button,
  Card,
  Carousel,
  Col,
  Divider,
  Empty,
  Input,
  Row,
  Tooltip,
} from 'antd';
import Meta from 'antd/lib/card/Meta';
import { debounce, filter, uniqBy } from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { PlanCoverApp, SearchIcon, UpgradeCrown } from '../../assets/svg';
import defaultIcon from '../../assets/svg/default-app.svg';
import {
  ACTIONS,
  APP_LIST_LIMIT,
  GA_EVENT,
  GA_LABEL,
  PLANS,
  ROUTES,
  TEAM_KEY,
} from '../../common/constants';
import { googleAnalyticsEvents, upgradeToProEvent } from '../../common/utils';
import AccessControl from '../../components/AccessControl';
import LoaderComponent from '../../components/LoaderComponent';
import { GET_USER_TEAM } from '../auth/graphql/queries';
import NoApps from './NoApps';
import AppServiceProFeatureModal from './components/AppServiceProFeatureModal';
import { GET_APPS } from './graphql/queries';
import './style/app-list.less';

function getQuery() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { search = '' } = useLocation();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useMemo(() => new URLSearchParams(search), [search]);
}

let searchDebounce = null;
let debounceScroll;

const AppList = () => {
  const history = useHistory();
  const [proFeatureModalOpen, setProFeatureModalOpen] = useState(false);
  const [isEnd, setIsEnd] = useState(false);
  const [appData, setAppData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [searchData, setSearchData] = useState();
  const [scrollFlag, setScrollFlag] = useState(false);
  // eslint-disable-next-line no-undef
  const workspaceId = localStorage?.getItem(TEAM_KEY);

  const query = getQuery();

  const {
    dispatch,
    state: {
      teamId,
      teamPlan,
      teamPlanUsage,
      teamPlanTotalUsage,
      userTeams,
      currentUser,
    },
  } = useContext(AppContext);

  useEffect(() => {
    if (query?.get('teamId')) {
      dispatch({
        type: 'SET_TEAM',
        data: query?.get('teamId'),
      });
      history?.push(ROUTES?.APPS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [getUserTeams] = useLazyQuery(GET_USER_TEAM, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      if (res?.getUserTeams?.length <= 0) {
        dispatch({
          type: 'SET_TEAM',
          data: null,
        });
        history?.push(ROUTES?.ONBOARD);
      }
    },
  });

  const [executeSearch, { loading }] = useLazyQuery(GET_APPS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const tempAppsData = [...appData, ...res?.getAppsList?.appsDetails];
        setAppData(uniqBy([...tempAppsData], 'id'));
        setScrollFlag(false);
      } else {
        setAppData(uniqBy([...res?.getAppsList?.appsDetails], 'id'));
      }
      setIsEnd(res?.getAppsList?.appsDetails?.length < APP_LIST_LIMIT);
      setLoader(false);
      setIsLoading(false);
      const value = filter(userTeams, (data) => data?.id === teamId);
      dispatch({
        type: 'SET_CURRENT_ROLE',
        data: value,
      });
    },
    onError() {
      setLoader(false);
      setIsLoading(false);
    },
  });

  useEffect(() => {
    setLoader(true);
    if (teamId) {
      executeSearch({
        variables: {
          data: {
            teamId: teamId || workspaceId,
            skip: 0,
            limit: APP_LIST_LIMIT,
          },
        },
      });
    } else {
      getUserTeams();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId]);

  const handleCreate = () => {
    googleAnalyticsEvents(GA_EVENT?.CLICK_ON_CREATE_APP, {
      label: GA_LABEL?.CLICK_ON_CREATE_APP,
      member_id: currentUser?.id,
      current_plan_name: teamPlan?.subscriptionPlan?.name,
      current_plan_type: teamPlan?.subscriptionPlan?.type,
    });
    if (appData?.length === Number(teamPlanTotalUsage?.APP)) {
      setProFeatureModalOpen(true);
    } else {
      history?.push(ROUTES?.CREATE_APP);
    }
  };

  const handleAppSearch = (value) => {
    googleAnalyticsEvents(GA_EVENT?.APP_SEARCH, {
      label: GA_LABEL?.APP_SEARCH,
      member_id: currentUser?.id,
      current_plan_name: teamPlan?.subscriptionPlan?.name,
      current_plan_type: teamPlan?.subscriptionPlan?.type,
    });
    executeSearch({
      variables: {
        data: {
          teamId,
          skip: 0,
          limit: APP_LIST_LIMIT,
          search: value,
        },
      },
    });
  };

  const handleScroll = (e) => {
    if (debounceScroll) {
      debounceScroll?.cancel();
    }
    const { target } = e;
    const { scrollTop, scrollHeight, offsetHeight } = target;

    debounceScroll = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 15;

      if (scrolledToBottom && !isEnd) {
        setScrollFlag(true);
        executeSearch({
          variables: {
            data: {
              teamId,
              skip: appData?.length,
              limit: APP_LIST_LIMIT,
            },
          },
        });
      }
    }, 500);
    debounceScroll();
  };

  const handleChange = ({ target: { value } }) => {
    setSearchData(value);
    setIsLoading(true);
    if (searchDebounce) {
      searchDebounce?.cancel();
      searchDebounce = null;
    }
    searchDebounce = debounce(handleAppSearch, 500);
    searchDebounce(value);
  };

  if (loader) {
    return <LoaderComponent setHeight={56} />;
  }

  return (
    <>
      {!appData?.length && !searchData?.length && !isLoading ? (
        <NoApps />
      ) : (
        <div className="apps-content">
          <div className="apps-header">
            <Row
              className="app-list-row"
              justify="space-between"
              align="middle"
            >
              <Col>
                <h1>
                  Your Apps
                  {teamPlanUsage &&
                    `(${teamPlanUsage?.app}/${teamPlanTotalUsage?.APP})`}
                </h1>
              </Col>
              <Col className="search-col">
                <Input
                  placeholder="Search"
                  className="app-search-input"
                  allowClear
                  onChange={handleChange}
                  suffix={<SearchIcon />}
                />
                <AccessControl allowedPermissions={ACTIONS?.CREATE_APP}>
                  <Button
                    type="primary"
                    onClick={handleCreate}
                    className="create-app-btn"
                  >
                    Create App
                  </Button>
                </AccessControl>
              </Col>
            </Row>
          </div>
          {isLoading ? (
            <LoaderComponent setHeight={150} />
          ) : (
            <>
              {appData?.length > 0 ? (
                <div
                  className={`${
                    loading ? 'app-list flex-vertical' : 'app-list'
                  }`}
                  onScroll={handleScroll}
                >
                  <Row className="app-card-row" gutter={[16, 24]}>
                    {appData?.map((app) => (
                      <Col key={app?.id} sm={24} md={12} lg={12} xl={8} xxl={6}>
                        <div key={app?.id}>
                          <Card
                            className="app-list-card"
                            bordered
                            key={app?.id}
                            onClick={() => {
                              history?.push(
                                `${ROUTES?.APPS}${ROUTES?.DETAILS}${ROUTES?.RELEASES}/${app?.id}`,
                              );
                              dispatch({
                                type: 'SET_APPLICATION_ID',
                                data: app?.id,
                              });
                            }}
                          >
                            <Meta
                              avatar={
                                <Avatar src={app?.appLogo || defaultIcon} />
                              }
                              title={
                                <span title={app?.appName}>{app?.appName}</span>
                              }
                              description={
                                <>
                                  <div className="app-list-info">
                                    <span>
                                      Last Updated{' '}
                                      {moment(
                                        app?.dashboard?.latestReleaseDate ||
                                          app?.updatedAt,
                                      )?.fromNow()}
                                    </span>
                                    <span>
                                      {app?.dashboard?.remainingDays <= 7 &&
                                        app?.dashboard?.remainingDays > 0 && (
                                          <Tooltip
                                            title={`Share link for latest build expires in ${app?.dashboard?.remainingDays} days`}
                                          >
                                            <InfoCircleOutlined className="app-list-info-icon" />
                                          </Tooltip>
                                        )}
                                    </span>
                                  </div>
                                </>
                              }
                            />
                            <Divider />
                            <Row>
                              <Col span={12} className="line-20">
                                Releases
                              </Col>
                              <Col span={12} className="line-20">
                                Downloads
                              </Col>
                            </Row>
                            <Row className="card-content-row">
                              <Col
                                className="card-content-col line-24"
                                span={12}
                              >
                                {app?.dashboard?.totalUsersUpload}
                              </Col>
                              <Col
                                className="card-content-col line-24"
                                span={12}
                              >
                                {app?.dashboard?.totalAppsDownload}
                              </Col>
                            </Row>
                          </Card>
                        </div>
                      </Col>
                    ))}
                  </Row>
                  {(loading || appData?.length === 0) && (
                    <LoaderComponent setHeight={5} />
                  )}
                </div>
              ) : (
                <div className="empty-data app-list-empty">
                  <Empty description="No Data" />
                </div>
              )}
            </>
          )}
          {proFeatureModalOpen && (
            <AppServiceProFeatureModal
              title="Apps"
              visible={proFeatureModalOpen}
              setVisible={setProFeatureModalOpen}
              footer={
                <div className="d-flex justify-center gap-24">
                  {teamPlan?.subscriptionPlan?.name !== PLANS?.PRO && (
                    <Button
                      type="primary"
                      className="upgrade-button"
                      onClick={() => {
                        upgradeToProEvent(currentUser, teamPlan);
                        history?.push(
                          `${ROUTES?.WORKSPACE_DETAILS}${ROUTES?.PLAN_AND_PAYMENT}/${teamId}`,
                        );
                      }}
                    >
                      <UpgradeCrown />
                      Upgrade to pro
                    </Button>
                  )}
                  <Button
                    className="add-ons-upgrade-button"
                    onClick={() => {
                      history?.push(
                        `${ROUTES?.WORKSPACE_DETAILS}${ROUTES?.MANAGE_ADD_ONS}/${teamId}`,
                      );
                    }}
                  >
                    Purchase add-ons
                  </Button>
                </div>
              }
              content={
                <Carousel autoplay draggable>
                  <div className="app-service-carousel">
                    <PlanCoverApp />
                    <div className="text">
                      {teamPlan?.name !== PLANS?.PRO ? (
                        <p>
                          Want more no. of apps? Increase the no. of apps to
                          your current workspace.
                        </p>
                      ) : (
                        <p>Please purchase App add-ons to add new apps.</p>
                      )}
                    </div>
                  </div>
                </Carousel>
              }
            />
          )}
        </div>
      )}
    </>
  );
};

export default AppList;
