import { EllipsisOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Dropdown, Table } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { WarningIcon } from '../../../assets/svg';
import {
  ADD_ON,
  GA_EVENT,
  GA_LABEL,
  MAX_ADD_ONS_QUANTITY,
  PAYMENT_STATUS,
} from '../../../common/constants';
import {
  currencyFormat,
  findEarliestRenewalDate,
  formatPurchaseDate,
  googleAnalyticsEvents,
  isDueWithin7Days,
} from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import PaymentModal from '../../../components/PaymentModal';
import AppServiceProFeatureModal from '../../apps/components/AppServiceProFeatureModal';
import PurchaseConfirmationModal from '../../apps/components/PurchaseConfirmationModal';
import CountdownModal from '../common/CountdownModal';
import { triggerPaddlePayment } from '../common/paddlePaymentHandlers';
import AddonDetails from './components/AddonDetails';
import AddonList from './components/AddonList';
import CancelAddOnModal from './components/CancelAddOnModal';
import CancelAddonRestrictionModal from './components/CancelAddonRestrictionModal';
import EditAddOnsQuantityModal from './components/EditAddOnsQuantityModal';
import PurchaseAgainModal from './components/PurchaseAgainModal';
import {
  CANCEL_ADD_ON,
  EDIT_ADD_ON,
  PURCHASE_ADD_ON,
} from './graphql/mutations';
import { ADD_ONS } from './graphql/queries';
import './manage-addon.less';

const ManageAddOns = () => {
  const {
    state: { teamId, teamPlan, teamPlanAddOns, currentUser },
    dispatch,
  } = useContext(AppContext);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [paymentModal, setPaymentModal] = useState(false);
  const [paymentModalStatus, setPaymentModalStatus] = useState('');
  const [
    cancelAddonRestrictionModal,
    setCancelAddonRestrictionModal,
  ] = useState(false);
  const [purchaseConfirmationModal, setPurchaseConfirmationModal] = useState(
    false,
  );
  const [addOnsData, setAddOnsData] = useState([]);
  const [purchaseAddOnName, setPurchaseAddOnName] = useState();
  const [purchaseAddOnId, setPurchaseAddOnId] = useState();
  const [addOnsState, setAddOnsState] = useState({});
  const [loading, setLoading] = useState(true);
  const [cancelAddOnModal, setCancelAddOnModal] = useState(false);
  const [editAddOnModal, setEditAddOnModal] = useState(false);
  const [purchaseAgainModal, setPurchaseAgainModal] = useState(false);
  const [selectedAddOn, setSelectedAddOn] = useState([]);
  const [usageExceedModal, setUsageExceedModal] = useState(false);
  const [countdownModalOpen, setCountdownModalOpen] = useState(false);

  const [addOns] = useLazyQuery(ADD_ONS, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      setAddOnsData(res?.addons?.data);
      res?.addons?.data?.forEach((item) => {
        let storageValue = item?.subscriptionFeatures?.STORAGE;
        if (storageValue != null) {
          storageValue = storageValue?.replace(/(\d+)(\w+)/, '$1 $2');
        }
        setAddOnsState((prevState) => ({
          ...prevState,
          [item.name]: {
            amount: item?.amount,
            feature: item?.subscriptionFeatures,
            quantity: 1,
            totalQuantity:
              item?.name === ADD_ON?.APP
                ? item?.subscriptionFeatures?.APP
                : storageValue,
            total: item?.amount,
            loading: false,
          },
        }));
      });
      setLoading(false);
    },
  });

  const [purchaseAddOn] = useMutation(PURCHASE_ADD_ON, {
    onCompleted(res) {
      const { transactionId } = res?.purchaseAddon?.data || {};
      if (transactionId) {
        triggerPaddlePayment(
          transactionId,
          () => {
            dispatch({
              type: 'SET_REFETCH_TEAM_PLAN_USAGE',
              data: true,
            });
            googleAnalyticsEvents(GA_EVENT?.PURCHASE_ADDON, {
              label: GA_LABEL?.PURCHASE_ADDON,
              member_id: currentUser?.id,
              purchase_addon_name: purchaseAddOnName,
              amount: addOnsState?.[purchaseAddOnName]?.total,
              quantity: addOnsState?.[purchaseAddOnName]?.quantity,
            });
            setPaymentModal(true);
            setPaymentModalStatus('success');
          },
          () => {},
        );
      } else {
        dispatch({
          type: 'SET_REFETCH_TEAM_PLAN_USAGE',
          data: true,
        });
        setCountdownModalOpen(true);
        googleAnalyticsEvents(GA_EVENT?.PURCHASE_ADDON, {
          label: GA_LABEL?.PURCHASE_ADDON,
          member_id: currentUser?.id,
          purchase_addon_name: purchaseAddOnName,
          amount: addOnsState?.[purchaseAddOnName]?.total,
          quantity: addOnsState?.[purchaseAddOnName]?.quantity,
        });
      }
      setAddOnsState((prevState) => ({
        ...prevState,
        [purchaseAddOnName]: {
          ...prevState[purchaseAddOnName],
          loading: false,
        },
      }));
    },
    onError() {
      setAddOnsState((prevState) => ({
        ...prevState,
        [purchaseAddOnName]: {
          ...prevState[purchaseAddOnName],
          loading: false,
        },
      }));
    },
  });

  const [cancelAddOn] = useMutation(CANCEL_ADD_ON, {
    onCompleted() {
      dispatch({
        type: 'SET_REFETCH_TEAM_PLAN_USAGE',
        data: true,
      });
      setCountdownModalOpen(true);
    },
    onError(errorRes) {
      const { graphQLErrors } = errorRes;
      if (
        graphQLErrors[0]?.extensions?.code ===
        'SUBSCRIPTION_CANCELLATION_RESTRICTED'
      ) {
        setCancelAddonRestrictionModal(true);
      }
    },
  });

  const [editAddOn] = useMutation(EDIT_ADD_ON, {
    onCompleted() {
      dispatch({
        type: 'SET_REFETCH_TEAM_PLAN_USAGE',
        data: true,
      });
      setCountdownModalOpen(true);
    },
    onError() {},
  });

  const handleAddOnPurchase = (purchaseId, addOnName) => {
    setPurchaseAddOnId(purchaseId);
    setPurchaseAddOnName(addOnName);
    setAddOnsState((prevState) => ({
      ...prevState,
      [addOnName]: {
        ...prevState[addOnName],
        loading: true,
      },
    }));
    googleAnalyticsEvents(GA_EVENT?.SELECT_ADDON, {
      label: GA_LABEL?.SELECT_ADDON,
      member_id: currentUser?.id,
      addon_name: addOnName,
      current_plan_name: teamPlan?.subscriptionPlan?.name,
      current_plan_type: teamPlan?.subscriptionPlan?.type,
    });

    if (teamPlanAddOns?.data?.length > 0) {
      setPurchaseAgainModal(true);
    } else {
      purchaseAddOn({
        variables: {
          data: {
            id: purchaseId,
            quantity: addOnsState?.[addOnName]?.quantity,
            teamId,
          },
        },
      });
    }
  };

  const onHandlePlus = (addOnType) => {
    if (addOnsState?.[addOnType]?.quantity <= MAX_ADD_ONS_QUANTITY) {
      setAddOnsState((prevState) => {
        const currentAmount = prevState[addOnType]?.amount || 1;
        const currentQuantity = prevState[addOnType]?.quantity || 1;
        const newQuantity = Math.max(currentQuantity + 1, 1);
        const totalAmount = Math.max(currentAmount * newQuantity);
        return {
          ...prevState,
          [addOnType]: {
            ...prevState[addOnType],
            quantity: newQuantity,
            totalQuantity:
              addOnType === ADD_ON?.APP
                ? newQuantity * 5
                : `${newQuantity * 10} GB`,
            total: totalAmount,
          },
        };
      });
    }
  };

  const onHandleMinus = (addOnType) => {
    if (addOnsState[addOnType]?.quantity > 1) {
      setAddOnsState((prevState) => {
        const currentAmount = prevState[addOnType]?.amount || 1;
        const currentQuantity = prevState[addOnType]?.quantity || 1;
        const newQuantity = Math.max(currentQuantity - 1, 1);
        const totalAmount = Math.max(currentAmount * newQuantity);
        return {
          ...prevState,
          [addOnType]: {
            ...prevState[addOnType],
            quantity: newQuantity,
            totalQuantity:
              addOnType === ADD_ON?.APP
                ? newQuantity * 5
                : `${newQuantity * 10} GB`,
            total: totalAmount,
          },
        };
      });
    }
  };

  const handleQuantityChange = (e, name) => {
    const initialValue = parseFloat(e?.target?.value) || 0;
    if (initialValue >= 0 && initialValue <= MAX_ADD_ONS_QUANTITY) {
      setAddOnsState((prevState) => {
        const currentAmount = prevState[name]?.amount || 1;
        const totalAmount = Math.max(currentAmount * initialValue);
        return {
          ...prevState,
          [name]: {
            ...prevState[name],
            quantity: initialValue,
            totalQuantity:
              name === ADD_ON?.APP
                ? initialValue * 5
                : `${initialValue * 10} GB`,
            total: totalAmount,
          },
        };
      });
    }
  };

  useEffect(() => {
    addOns();
  }, []);

  useEffect(() => {
    if (params.get('payment') === 'success') {
      setPaymentModal(true);
      setPaymentModalStatus('success');
    }
    if (params.get('payment') === 'failed') {
      setPaymentModal(true);
      setPaymentModalStatus('failed');
    }
  }, [location.search]);

  const manageAddOnsColumns = [
    {
      title: 'Add-ons Name',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => record?.subscriptionPlan?.label,
    },
    {
      title: 'Purchase Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record) => formatPurchaseDate(record?.createdAt),
    },
    {
      title: 'Quantity',
      key: 'quantity',
      dataIndex: 'quantity',
    },
    {
      title: 'Total Bill',
      key: 'subscriptionAmount',
      dataIndex: 'subscriptionAmount',
      render: (_, record) =>
        currencyFormat.format(record?.subscriptionAmount || 0),
    },
    {
      title: 'Status',
      dataIndex: 'subscriptionStatus',
      key: 'subscriptionStatus',
      render: (_, record) => {
        if (record?.subscriptionStatus === PAYMENT_STATUS.COMPLETED.key) {
          return <span className="active">Completed</span>;
        }
        return <span className="cancelled">Cancelled</span>;
      },
    },
    {
      title: ' ',
      key: 'action',
      render: (_, record) => {
        const dropdownItems = [];

        if (record.subscriptionStatus === PAYMENT_STATUS.COMPLETED.key) {
          if (record.subscriptionPlan?.name === ADD_ON?.TEAM_MEMBERS) {
            dropdownItems.push({
              key: 'cancel',
              label: 'Cancel',
              onClick: () => setCancelAddOnModal(true),
            });
          } else if (record.quantity > 1) {
            dropdownItems.push(
              {
                key: 'edit',
                label: 'Edit',
                onClick: () => setEditAddOnModal(true),
              },
              {
                key: 'cancel',
                label: 'Cancel',
                onClick: () => setCancelAddOnModal(true),
              },
            );
          } else {
            dropdownItems.push({
              key: 'cancel',
              label: 'Cancel',
              onClick: () => setCancelAddOnModal(true),
            });
          }
        } else {
          dropdownItems.push({
            key: 'edit',
            label: 'Edit',
            onClick: () => setEditAddOnModal(true),
          });
        }

        return (
          <Dropdown
            overlayClassName="add-on-manage-menu"
            menu={{ items: dropdownItems }}
            trigger={['click']}
            placement="bottomRight"
            containsTeamMembers
            getPopupContainer={(triggerNode) => triggerNode}
            dropdownRender={(menu) => (
              <div className="app-name-header text-left">
                {React.cloneElement(menu)}
              </div>
            )}
          >
            <EllipsisOutlined
              rotate={90}
              onClick={() => setSelectedAddOn(record)}
            />
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
      {teamPlanAddOns?.data?.length > 0 &&
        isDueWithin7Days(
          formatPurchaseDate(findEarliestRenewalDate(teamPlanAddOns?.data)),
        ) && (
          <div className="payment-banner">
            <p>Your payment is due</p>
          </div>
        )}
      {loading ? (
        <LoaderComponent setHeight={150} />
      ) : (
        <div className="manage-add-on">
          <div className="manage-add-ons">
            <AddonDetails teamPlanAddOns={teamPlanAddOns} />
            <AddonList
              addOnsData={addOnsData}
              addOnsState={addOnsState}
              onHandleMinus={onHandleMinus}
              handleQuantityChange={handleQuantityChange}
              onHandlePlus={onHandlePlus}
              handleAddOnPurchase={handleAddOnPurchase}
            />
            <p className="available-text">
              Available to all free, pro and enterprise accounts.
            </p>
            <div className="manage-add-ons-accordion">
              <h3 className="title">Manage Add-ons</h3>
              <div className="manage-add-ons-list">
                <Table
                  rowKey={(e) => e?.id}
                  columns={manageAddOnsColumns}
                  dataSource={teamPlanAddOns?.data}
                  pagination={false}
                />
              </div>
            </div>
          </div>
          {cancelAddOnModal && (
            <CancelAddOnModal
              visible={cancelAddOnModal}
              setVisible={setCancelAddOnModal}
              selectedAddOn={selectedAddOn}
              setSelectedAddOn={setSelectedAddOn}
              cancelAddOn={cancelAddOn}
            />
          )}
          {editAddOnModal && (
            <EditAddOnsQuantityModal
              visible={editAddOnModal}
              setVisible={setEditAddOnModal}
              selectedAddOn={selectedAddOn}
              setSelectedAddOn={setSelectedAddOn}
              editAddOn={editAddOn}
            />
          )}
          {paymentModal && (
            <PaymentModal
              visible={paymentModal}
              setVisible={setPaymentModal}
              paymentModalStatus={paymentModalStatus}
              setCountdownModalOpen={setCountdownModalOpen}
            />
          )}
          {purchaseConfirmationModal && (
            <PurchaseConfirmationModal
              visible={purchaseConfirmationModal}
              setVisible={setPurchaseConfirmationModal}
            />
          )}
          {purchaseAgainModal && (
            <PurchaseAgainModal
              visible={purchaseAgainModal}
              setVisible={setPurchaseAgainModal}
              handleAgainCancel={() => {
                setAddOnsState((prevState) => ({
                  ...prevState,
                  [purchaseAddOnName]: {
                    ...prevState[purchaseAddOnName],
                    loading: false,
                  },
                }));
              }}
              handleAgainPurchase={() => {
                purchaseAddOn({
                  variables: {
                    data: {
                      id: purchaseAddOnId,
                      quantity: addOnsState?.[purchaseAddOnName]?.quantity,
                      teamId,
                    },
                  },
                });
              }}
            />
          )}
          {usageExceedModal && (
            <AppServiceProFeatureModal
              modalWidth={520}
              wrapClassName="usage-exceed-modal"
              visible={usageExceedModal}
              setVisible={setUsageExceedModal}
              footer={false}
              content={
                <div className="d-flex flex-vertical align-center">
                  <div className="payment-modal-content d-flex flex-vertical align-center text-center gap-24">
                    <WarningIcon />
                    <div>
                      <p className="m-0">
                        We're sorry, but we couldn't process your request to
                        cancel the add-ons at this time. Your current usage
                        exceeds the free allotted usage limit. To cancel the
                        add-ons, please ensure your usage falls within the free
                        given limit. Reduce or adjust your usage accordingly to
                        proceed with the cancellation. Thank you for your
                        understanding.
                      </p>
                    </div>
                  </div>
                </div>
              }
            />
          )}
          {countdownModalOpen && (
            <CountdownModal
              countdownModalOpen={countdownModalOpen}
              setCountdownModalOpen={setCountdownModalOpen}
            />
          )}
          {cancelAddonRestrictionModal && (
            <CancelAddonRestrictionModal
              selectedAddOn={selectedAddOn}
              visible={cancelAddonRestrictionModal}
              handleVisible={setCancelAddonRestrictionModal}
            />
          )}
        </div>
      )}
    </>
  );
};

export default ManageAddOns;
