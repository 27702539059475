import { Layout } from 'antd';
import React from 'react';
import footerLogo from '../assets/images/logicwind-footer-logo.png';
import { LOGICWIND_URL } from '../common/constants';

function CommonFooter() {
  const { Footer } = Layout;

  return (
    <Footer className="common-footer">
      <div>© {new Date()?.getFullYear()} AppsOnAir</div>
      <div className="d-flex align-center">
        Crafted Mindfully at
        <a href={LOGICWIND_URL} target="_blank" rel="noreferrer">
          <img
            className="footer-image"
            src={footerLogo}
            width="120"
            alt="Logicwind"
          />
        </a>
      </div>
    </Footer>
  );
}

export default CommonFooter;
