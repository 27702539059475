import { Button } from 'antd';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import noAppsImg from '../../assets/images/no-apps-img.png';
import { ACTIONS, GA_EVENT, GA_LABEL, ROUTES } from '../../common/constants';
import { googleAnalyticsEvents } from '../../common/utils';
import AccessControl from '../../components/AccessControl';
import './style/no-apps.less';

const NoApps = () => {
  const {
    state: { teamPlan, currentUser },
  } = useContext(AppContext);
  const history = useHistory();

  const handleClick = () => {
    googleAnalyticsEvents(GA_EVENT?.CLICK_ON_CREATE_APP, {
      label: GA_LABEL?.CLICK_ON_CREATE_APP,
      member_id: currentUser?.id,
      current_plan_name: teamPlan?.subscriptionPlan?.name,
      current_plan_type: teamPlan?.subscriptionPlan?.type,
    });
    history?.push(ROUTES?.CREATE_APP);
  };
  return (
    <div className="no-apps-div">
      <div className="no-apps-img">
        <img src={noAppsImg} alt="no-apps" />
      </div>
      <div className="no-apps-content">
        <h1>No apps in your workspace</h1>
        <AccessControl allowedPermissions={ACTIONS?.CREATE_APP}>
          <div className="no-apps-release-content">
            <p>Create an app and add releases in it</p>
          </div>

          <div className="no-apps-btn">
            <Button
              type="primary"
              onClick={handleClick}
              className="create-app-btn"
            >
              Create App
            </Button>
          </div>
        </AccessControl>
      </div>
    </div>
  );
};

export default NoApps;
