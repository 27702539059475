import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { AppContext } from '../../../AppContext';
import {
  GA_EVENT,
  GA_LABEL,
  PLANS,
  PLANS_TYPE,
  ROUTES,
} from '../../../common/constants';
import {
  formatPurchaseDate,
  googleAnalyticsEvents,
  isDueWithin7Days,
} from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import PaymentModal from '../../../components/PaymentModal';
import history from '../../../historyData';
import PurchaseConfirmationModal from '../../apps/components/PurchaseConfirmationModal';
import CountdownModal from '../common/CountdownModal';
import CancelPlanRestrictionModal from './components/CancelPlanRestrictionModal';
import CancelSubsCriptionModal from './components/CancelSubscriptionModal';
import FaqSection from './components/FaqSection';
import PlansInfo from './components/PlansInfo';
import PricingOfPlans from './components/PricingOfPlans';
import { CANCEL_SUBSCRIPTION } from './graphql/mutations';
import { SUBSCRIPTION_PLANS } from './graphql/queries';
import './plan-and-payment.less';

const PlanAndPayment = () => {
  const {
    state: { teamPlan, teamId, currentUser },
    dispatch,
  } = useContext(AppContext);
  const [paymentModal, setPaymentModal] = useState(false);
  const [paymentModalStatus, setPaymentModalStatus] = useState('');
  const [purchaseConfirmationModal, setPurchaseConfirmationModal] = useState(
    false,
  );
  const [plansData, setPlansData] = useState([]);
  const [monthlyPlans, setMonthlyPlans] = useState([]);
  const [yearlyPlans, setYearlyPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [cancelSubscriptionModal, setCancelSubscriptionModal] = useState(false);
  const [countdownModalOpen, setCountdownModalOpen] = useState(false);
  const [cancelPlanRestrictionModal, setCancelPlanRestrictionModal] = useState(
    false,
  );

  const [cancelSubscription] = useMutation(CANCEL_SUBSCRIPTION, {
    onCompleted() {
      googleAnalyticsEvents(GA_EVENT?.CANCEL_PLAN, {
        label: GA_LABEL?.CANCEL_PLAN,
        member_id: currentUser?.id,
        current_plan_name: teamPlan?.subscriptionPlan?.name,
        current_plan_type: teamPlan?.subscriptionPlan?.type,
      });
      dispatch({
        type: 'SET_REFETCH_TEAM_PLAN_USAGE',
        data: true,
      });
      setLoading(false);
      setCountdownModalOpen(true);
    },
    onError(errorRes) {
      const { graphQLErrors } = errorRes;
      if (
        graphQLErrors[0]?.extensions?.code ===
        'SUBSCRIPTION_CANCELLATION_RESTRICTED'
      ) {
        setCancelPlanRestrictionModal(true);
      }
      setLoading(false);
    },
  });

  const [subscriptionPlans] = useLazyQuery(SUBSCRIPTION_PLANS, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      setPlansData(res?.subscriptionPlans?.data);
      setLoading(false);
    },
    onError() {
      setLoading(false);
    },
  });

  const handleManageBilling = () => {
    googleAnalyticsEvents(GA_EVENT?.MANAGE_BILLING, {
      label: GA_LABEL?.MANAGE_BILLING,
      member_id: currentUser?.id,
      current_plan_name: teamPlan?.subscriptionPlan?.name,
      current_plan_type: teamPlan?.subscriptionPlan?.type,
    });
    history.push(
      `${ROUTES.WORKSPACE_DETAILS}${ROUTES.BILLING_HISTORY}/${teamId}`,
    );
  };

  const handleCancelPlan = () => {
    googleAnalyticsEvents(GA_EVENT?.CANCEL_PLAN_INITIATED, {
      label: GA_LABEL?.CANCEL_PLAN_INITIATED,
      member_id: currentUser?.id,
      current_plan_name: teamPlan?.subscriptionPlan?.name,
      current_plan_type: teamPlan?.subscriptionPlan?.type,
    });
    setCancelSubscriptionModal(true);
  };

  useEffect(() => {
    if (plansData) {
      const monthly = plansData.filter(
        (plan) =>
          plan.type === PLANS_TYPE?.FREE.key ||
          plan.type === PLANS_TYPE?.MONTHLY.key,
      );
      const yearly = plansData.filter(
        (plan) =>
          plan.type === PLANS_TYPE.FREE.key ||
          plan.type === PLANS_TYPE.ANNUAL.key,
      );
      setMonthlyPlans(monthly);
      setYearlyPlans(yearly);
    }
  }, [plansData]);

  useEffect(() => {
    subscriptionPlans();
  }, []);

  const paymentFeedback = (status = 'success' /* success | failed */) => {
    setPaymentModal(true);
    setPaymentModalStatus(status);
  };

  const freePlan = useMemo(
    () => plansData?.find((plan) => plan?.name === 'FREE'),
    [plansData],
  );

  return (
    <>
      {isDueWithin7Days(
        formatPurchaseDate(teamPlan?.subscriptionRenewalDate),
      ) && (
        <div className="payment-banner">
          <p>Your payment is due</p>
        </div>
      )}

      <LoaderComponent setHeight={150} spinning={loading}>
        <div className="plan-and-payments">
          <div className="plan-and-payment">
            {(teamPlan?.subscriptionPlan?.name === PLANS?.PRO ||
              teamPlan?.subscriptionPlan?.name === PLANS?.LIFETIME) && (
              <h1 className="page-title">Plan details</h1>
            )}
            <PlansInfo
              handleCancelPlan={handleCancelPlan}
              handleManageBilling={handleManageBilling}
            />
            <div className="plans-section">
              <PricingOfPlans
                monthlyPlans={monthlyPlans}
                yearlyPlans={yearlyPlans}
                handleVisible={setCancelSubscriptionModal}
                paymentFeedback={paymentFeedback}
                setCountdownModalOpen={setCountdownModalOpen}
              />
              <FaqSection />
            </div>
          </div>
        </div>
      </LoaderComponent>
      {cancelSubscriptionModal && (
        <CancelSubsCriptionModal
          visible={cancelSubscriptionModal}
          handleVisible={setCancelSubscriptionModal}
          cancelSubscription={cancelSubscription}
          isPlansInfoLoading={setLoading}
        />
      )}
      {paymentModal && (
        <PaymentModal
          visible={paymentModal}
          setVisible={setPaymentModal}
          paymentModalStatus={paymentModalStatus}
          setCountdownModalOpen={setCountdownModalOpen}
        />
      )}
      {purchaseConfirmationModal && (
        <PurchaseConfirmationModal
          visible={purchaseConfirmationModal}
          setVisible={setPurchaseConfirmationModal}
        />
      )}
      {countdownModalOpen && (
        <CountdownModal
          countdownModalOpen={countdownModalOpen}
          setCountdownModalOpen={setCountdownModalOpen}
        />
      )}
      {cancelPlanRestrictionModal && (
        <CancelPlanRestrictionModal
          visible={cancelPlanRestrictionModal}
          handleVisible={setCancelPlanRestrictionModal}
          freePlan={freePlan}
        />
      )}
    </>
  );
};

export default PlanAndPayment;
